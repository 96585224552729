import { useContext } from 'react';
import LookupLink from '../sharedComponents/lookupLink';
import VCLDataGrid from '../sharedComponents/vclDataGrid';
import { Constants, GlobalSettingsContext } from 'vcl-common';

export default function GlobalAdmins() {
  const globalSettings: any = useContext(GlobalSettingsContext);

  const columns = [
    {
      field: 'name',
      headerName: 'Full Name',
      width: 300,
      sortable: false,
      editable: false,
      valueGetter: (params: {
        value: null;
        row: { user: { fullName: any } };
      }) => {
        if (params.value !== null) {
          return params.row.user.fullName;
        } else return null;
      },
    },
    {
      field: 'username',
      headerName: 'Username',
      width: 300,
      sortable: false,
      editable: false,
      valueGetter: (params: { row: { user: { userName: any } } }) => {
        return params.row.user.userName;
      },
    },
    {
      field: 'userId',
      headerName: 'User',
      width: 300,
      sortable: false,
      editable: false,
      renderCell: (params: { value: null; row: { userId: any } }) => {
        if (params.value !== null) {
          return (
            <LookupLink
              link={`${Constants.routes.adminCenter.users}/${params.row.userId}`}
              displayText="User"
            />
          );
        } else return null;
      },
    },
  ];

  return (
    <VCLDataGrid
      title="Global Admins"
      apiUrl={Constants.routes.api.globalAdmins}
      entityRoute={Constants.routes.adminCenter.globalAdmins}
      getPersonalizedData={false}
      gridColumns={columns}
      canUserAdd={globalSettings.userInfo.isGlobalAdmin}
      canUserViewGrid={globalSettings.userInfo.isGlobalAdmin}
    />
  );
}
