import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import { makeStyles } from 'tss-react/mui';
import { useContext, useState, useRef, useCallback } from 'react';
import {
  VclApi,
  GlobalSettingsContext,
  AuthContext,
  MSALAuthService,
} from 'vcl-common';
import { validateMetadata } from '../utils/validators';

const useStyles = makeStyles()((theme) => ({
  validationError: {
    '& label': {
      color: '#d32f2f !important',
    },
    '& fieldset': {
      borderColor: '#d32f2f !important',
    },
    '& p': {
      color: '#d32f2f !important',
    },
  },
}));

export default function LazyAutocomplete(props: any) {
  const {
    initialValue,
    onNavigate,
    label,
    filterprop,
    filterval,
    error,
    apiUrl,
    metadata,
    setMetadata,
    idColumnName,
    valColumnName,
    required,
  } = props;

  const [value, setValue] = useState(initialValue);
  const [inputValue, setInputValue] = useState('');
  const [open, setOpen] = useState(false);
  const allItems = useRef<any[]>([]);
  const [options, setOptions] = useState([]);
  const settingsContext: any = useContext(GlobalSettingsContext);
  const authContext = useContext(AuthContext);
  const authService = authContext.authService as MSALAuthService;
  const { classes } = useStyles();

  const loading = open && options.length === 0;

  const loadData = useCallback(async () => {
    if (authService.isSignedIn()) {
      authService.signIn();
    }
    const msalAuthToken = await authService.getAccessToken();
    const api = new VclApi(settingsContext.organization.id);
    try {
      return api.getAll(apiUrl, true, msalAuthToken as any);
    } catch (error) {
      console.error(error);
      return [];
    }
  }, [apiUrl, authService, settingsContext.organization.id]);

  const handleOpen = async () => {
    const filterItems = (items: any) => {
      if (filterprop && filterval) {
        const filteredItems = items.filter((item: any) => {
          return item[filterprop] === filterval;
        });
        setOptions(filteredItems);
      } else setOptions(items);
    };

    if (allItems.current.length === 0) {
      const items = await loadData();
      allItems.current = items;
      filterItems(items);
    } else {
      filterItems(allItems.current);
    }
  };

  const navClick = () => {
    onNavigate(value);
  };

  const handleChange = (selectedItem: any) => {
    const updatedMetadata = metadata;
    updatedMetadata[valColumnName].value = selectedItem;

    setMetadata(
      validateMetadata(updatedMetadata, {
        target: {
          name: idColumnName,
          value: selectedItem ? selectedItem.id : null,
        },
      }),
    );
  };

  return (
    <Autocomplete
      id="asynchronous-demo"
      sx={{ width: 300 }}
      value={initialValue}
      onChange={(event, newValue) => {
        setValue(newValue);
        handleChange(newValue);
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      open={open}
      onOpen={() => {
        setOpen(true);
        handleOpen();
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) => option.title === value.title}
      getOptionLabel={(option) => option.title}
      options={options}
      loading={loading}
      className={error === true ? classes.validationError : ''}
      disabled={props.disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          required={required}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
            startAdornment: (
              <IconButton color="secondary" size="small" onClick={navClick}>
                {onNavigate === undefined || open || !value ? null : (
                  <OpenInNewIcon />
                )}
              </IconButton>
            ),
          }}
        />
      )}
    />
  );
}
