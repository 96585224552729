import { useContext } from 'react';
import VCLDataGrid from '../sharedComponents/vclDataGrid';
import {
  Constants,
  GlobalSettingsContext,
  getHostDependentImageUrl,
} from 'vcl-common';
import { Avatar } from '@mui/material';

export default function Organizations() {
  const globalSettings: any = useContext(GlobalSettingsContext);

  const columns = [
    {
      field: 'thumbnailUrl',
      headerName: '',
      width: 50,
      sortable: false,
      renderCell: (params: { value: string }) => {
        return <Avatar src={getHostDependentImageUrl(params.value)} alt="" />;
      },
    },
    {
      field: 'title',
      headerName: 'Title',
      width: 300,
      editable: false,
      sortable: false,
    },
  ];

  return (
    <VCLDataGrid
      title="Organizations"
      apiUrl={Constants.routes.api.organizations}
      entityRoute={Constants.routes.adminCenter.organizations}
      getPersonalizedData={true}
      gridColumns={columns}
      canUserAdd={globalSettings.userInfo.isGlobalAdmin}
      canUserViewGrid={globalSettings.userInfo.isOrgAdminOfAny}
    />
  );
}
