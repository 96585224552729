export const PageState = {
  VALIDATION_ERROR: 0,
  UNTOUCHED: 1,
  DIRTY: 2,
  SAVING: 3,
  SAVE_SUCCESS: 4,
  SAVE_ERROR: 5,
};
Object.freeze(PageState);

export const createEntityFromPageMetadata = (
  mdState: { [s: string]: any } | ArrayLike<any>,
) => {
  const entity: any = {};
  for (const [key, value] of Object.entries(mdState)) {
    entity[key] = value.value;
  }
  return entity;
};
