import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Hidden from '@mui/material/Hidden';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { makeStyles } from 'tss-react/mui';
import PropTypes from 'prop-types';
import { useContext, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ListItemLink from './sharedComponents/listItemLink';
import UserAvatar from './sharedComponents/userAvatar';
import {
  GlobalSettingsContext,
  UIContext,
  Constants,
  AuthContext,
  MSALAuthService,
} from 'vcl-common';
import Channel from './mainComponents/channel';
import Channels from './mainComponents/channels';
import GlobalConfig from './mainComponents/globalConfig';
import GlobalAdmin from './mainComponents/globalAdmin';
import GlobalAdmins from './mainComponents/globalAdmins';
import Location from './mainComponents/location';
import Locations from './mainComponents/locations';
import Organization from './mainComponents/organization';
import Organizations from './mainComponents/organizations';
import Region from './mainComponents/region';
import Regions from './mainComponents/regions';
import User from './mainComponents/user';
import Users from './mainComponents/users';
import Webcast from './mainComponents/webcast';
import Webcasts from './mainComponents/webcasts';
import WebcastSummary from './mainComponents/webcastSummary';
import OrgSettings from './mainComponents/orgSettings';
import OrgSetting from './mainComponents/orgSetting';
import GlobalConfigValue from './mainComponents/globalConfigValue';
import ProgressSpinner from './sharedComponents/progressSpinner';
import { Link } from '@mui/material';
import Contact from './mainComponents/contact';

const drawerWidth = 200;

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: 'auto',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      width: '80%',
    },
    width: '100%',
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  toolbar: {
    ...(theme.mixins.toolbar as any),
  },
  // toolbar: theme.mixins.toolbar,
}));
export default function AdminCenter(props: any) {
  const { window } = props;
  const authContext = useContext(AuthContext);
  const authService = authContext.authService as MSALAuthService;

  const { classes } = useStyles() as any;

  const uiContext = useContext(UIContext);
  const globalSettings: any = useContext(GlobalSettingsContext);

  const [mobileOpen, setMobileOpen] = useState(false);

  document.title = 'Volvo Cars Live - Admin Center';

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = globalSettings && !globalSettings.error && (
    <div className="adminCenterToolbar">
      <div className={classes.toolbar} />
      <Divider />

      <List>
        {globalSettings.userInfo.isWebcastAdminOfAny === true && (
          <ListItemLink
            to={Constants.routes.adminCenter.webcasts}
            primary="Webcasts"
          />
        )}
        {globalSettings.userInfo.isChannelAdminOfAny === true && (
          <ListItemLink
            to={Constants.routes.adminCenter.channels}
            primary="Channels"
          />
        )}
      </List>
      <Divider />
      <List>
        {globalSettings.userInfo.isRegionAdminOfAny === true && (
          <ListItemLink
            to={Constants.routes.adminCenter.regions}
            primary="Regions"
          />
        )}
        {globalSettings.userInfo.isRegionAdminOfAny === true && (
          <ListItemLink
            to={Constants.routes.adminCenter.locations}
            primary="Locations"
          />
        )}
        {globalSettings.userInfo.isOrgAdminOfAny === true && (
          <ListItemLink
            to={Constants.routes.adminCenter.orgSettings}
            primary={globalSettings.organization.title + ' Settings'}
          />
        )}
        {globalSettings.userInfo.isOrgAdminOfAny === true && (
          <ListItemLink
            to={Constants.routes.adminCenter.organizations}
            primary="Organizations"
          />
        )}
        {globalSettings.userInfo.isGlobalAdmin === true && (
          <ListItemLink
            to={Constants.routes.adminCenter.globalAdmins}
            primary="Global Admins"
          />
        )}
        {globalSettings.userInfo.isGlobalAdmin === true && (
          <ListItemLink
            to={Constants.routes.adminCenter.users}
            primary="Users"
          />
        )}
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
      />
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <AuthenticatedTemplate>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                className={classes.menuButton}
                size="large"
              >
                <MenuIcon />
              </IconButton>
            </AuthenticatedTemplate>
            <Typography
              variant="h6"
              noWrap
              sx={{ flexGrow: 1 }}
              component="div"
            >
              Volvo Cars Live - Admin Center
            </Typography>
            <AuthenticatedTemplate>
              <Link
                href={Constants.routes.adminCenter.contact}
                mr={2}
                underline="none"
                color="inherit"
              >
                Contact
              </Link>
              <UserAvatar />
              <IconButton
                color="inherit"
                aria-label="Sign out"
                edge="end"
                onClick={() => authService.signOut()}
                size="large"
              >
                <LogoutIcon />
              </IconButton>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
              <IconButton
                color="inherit"
                aria-label="Sign in"
                edge="end"
                onClick={() => authService.signIn()}
                size="large"
              >
                <LoginIcon />
              </IconButton>
            </UnauthenticatedTemplate>
          </Toolbar>
        </AppBar>
        <AuthenticatedTemplate>
          {!globalSettings && (
            <main className={classes.content}>
              <div className={classes.toolbar} />
              <ProgressSpinner />
            </main>
          )}
          {globalSettings?.error && (
            <Dialog
              open={true}
              scroll="paper"
              aria-labelledby="scroll-dialog-title"
              aria-describedby="scroll-dialog-description"
            >
              <DialogTitle id="scroll-dialog-title">Error</DialogTitle>
              <DialogContent dividers={false}>
                <Typography paragraph>
                  Something went wrong: {globalSettings?.errorMessage}
                </Typography>
                <Typography paragraph>Please refresh to try again.</Typography>
              </DialogContent>
            </Dialog>
          )}
          {!globalSettings?.error && globalSettings?.organization.id > 0 && (
            <Router>
              <nav className={classes.drawer} aria-label="main menu">
                <Hidden mdUp implementation="css">
                  <Drawer
                    container={container}
                    variant="temporary"
                    anchor="left"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    onClick={handleDrawerToggle}
                    classes={{
                      paper: classes.drawerPaper,
                    }}
                    ModalProps={{
                      keepMounted: true, // Better open performance on mobile.
                    }}
                  >
                    {drawer}
                  </Drawer>
                </Hidden>
                <Hidden mdDown implementation="css">
                  <Drawer
                    classes={{
                      paper: classes.drawerPaper,
                    }}
                    variant="permanent"
                    open
                  >
                    {drawer}
                  </Drawer>
                </Hidden>
              </nav>
              <main className={classes.content}>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={uiContext.currentLocale}
                >
                  <div className={classes.toolbar} />
                  <Switch>
                    <Route exact path={Constants.routes.adminCenter.webcasts}>
                      <Webcasts />
                    </Route>
                    <Route
                      exact
                      path={`${Constants.routes.adminCenter.webcasts}/:itemId`}
                    >
                      <Webcast />
                    </Route>
                    <Route
                      path={`${Constants.routes.adminCenter.webcastSummary}/:itemId`}
                    >
                      <WebcastSummary />
                    </Route>
                    <Route exact path={Constants.routes.adminCenter.channels}>
                      <Channels />
                    </Route>
                    <Route
                      path={`${Constants.routes.adminCenter.channels}/:itemId`}
                    >
                      <Channel />
                    </Route>
                    <Route exact path={Constants.routes.adminCenter.regions}>
                      <Regions />
                    </Route>
                    <Route
                      path={`${Constants.routes.adminCenter.regions}/:itemId`}
                    >
                      <Region />
                    </Route>
                    <Route exact path={Constants.routes.adminCenter.locations}>
                      <Locations />
                    </Route>
                    <Route
                      path={`${Constants.routes.adminCenter.locations}/:itemId`}
                    >
                      <Location />
                    </Route>
                    <Route
                      exact
                      path={Constants.routes.adminCenter.globalConfig}
                    >
                      <GlobalConfig />
                    </Route>
                    <Route
                      exact
                      path={`${Constants.routes.adminCenter.globalConfig}/:itemId`}
                    >
                      <GlobalConfigValue />
                    </Route>
                    <Route
                      exact
                      path={Constants.routes.adminCenter.orgSettings}
                    >
                      <OrgSettings />
                    </Route>
                    <Route
                      exact
                      path={`${Constants.routes.adminCenter.orgSettings}/:itemId`}
                    >
                      <OrgSetting />
                    </Route>
                    <Route
                      exact
                      path={Constants.routes.adminCenter.organizations}
                    >
                      <Organizations />
                    </Route>
                    <Route
                      path={`${Constants.routes.adminCenter.organizations}/:itemId`}
                    >
                      <Organization />
                    </Route>
                    <Route exact path={Constants.routes.adminCenter.users}>
                      <Users />
                    </Route>
                    <Route
                      path={`${Constants.routes.adminCenter.users}/:itemId`}
                    >
                      <User />
                    </Route>
                    <Route
                      exact
                      path={Constants.routes.adminCenter.globalAdmins}
                    >
                      <GlobalAdmins />
                    </Route>
                    <Route
                      path={`${Constants.routes.adminCenter.globalAdmins}/:itemId`}
                    >
                      <GlobalAdmin />
                    </Route>
                    <Route exact path={Constants.routes.adminCenter.contact}>
                      <Contact />
                    </Route>
                    <Route path="/">
                      <Webcasts />
                    </Route>
                  </Switch>
                </LocalizationProvider>
              </main>
            </Router>
          )}
        </AuthenticatedTemplate>
      </div>
    </>
  );
}

AdminCenter.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};
