import { AxiosResponse } from 'axios';

export const isCurrentUserViewerOfThis = (roleArray: string | string[]) => {
  return roleArray.indexOf('Viewer') >= 0;
};

export const isCurrentUserEditorOfThis = (roleArray: string | string[]) => {
  return roleArray.indexOf('Editor') >= 0;
};

const viewerPermission = {
  id: 0,
  title: 'Viewer',
};

const adminPermission = {
  id: 1,
  title: 'Admin',
};

const techPermission = {
  id: 2,
  title: 'Technician',
};

export const webcastAvailablePermissions = [
  viewerPermission,
  adminPermission,
  techPermission,
];
export const channelAvailablePermissions = [adminPermission];
export const regionAvailablePermissions = [adminPermission];
export const organizationAvailablePermissions = [adminPermission];
export const locationAvailablePermissions = [techPermission];

export const savePermsAsync = async (
  apiUrl: any,
  objectId: number,
  permissionsToUpdate: string | any[],
  apiUpdateFunc: {
    (
      url: string,
      id: string | number,
      updatedPermission: { [x: string]: any },
      msalAuthToken: string | undefined,
    ): Promise<AxiosResponse<unknown, any>>;
    (arg0: any, arg1: any, arg2: any, arg3: any): any;
  },
  permissionsToDelete: string | any[],
  apiDelFunc: {
    (
      url: string,
      id: string | number,
      permissionIdsToRemove: (string | number)[],
      msalAuthToken: string | undefined,
    ): Promise<AxiosResponse<unknown, any>>;
    (arg0: any, arg1: any, arg2: any, arg3: any): any;
  },
  permissionsToAdd: any[],
  apiAddFunc: {
    (
      url: string,
      id: string | number,
      permissionLevelId: string,
      authProviderId: string,
      userArray: { userId: string; email?: string | undefined }[],
      msalAuthToken: string | undefined,
    ): Promise<AxiosResponse<unknown, any>>;
    (arg0: any, arg1: any, arg2: any, arg3: any, arg4: any, arg5: any): any;
  },
  availablePermissions: string | any[],
  msalAuthToken: string | null,
) => {
  for (let i = 0; i < permissionsToUpdate.length; i++) {
    await apiUpdateFunc(
      apiUrl,
      objectId,
      permissionsToUpdate[i],
      msalAuthToken,
    );
  }

  if (permissionsToDelete.length > 0) {
    await apiDelFunc(apiUrl, objectId, permissionsToDelete, msalAuthToken);
  }

  if (permissionsToAdd.length > 0 && availablePermissions) {
    // Group by permission levels, then add in batches
    for (let i = 0; i < availablePermissions.length; i++) {
      const permLevel = availablePermissions[i];
      const currentLevelPermissions = permissionsToAdd
        .filter((user) => user.permLevelId === permLevel.id)
        .map((user) => {
          return {
            userName: user.userName,
            fullName: user.fullName,
            federatedUserId: user.federatedUserId,
            identityProvider: user.identityProvider,
          };
        });
      if (currentLevelPermissions.length > 0) {
        await apiAddFunc(
          apiUrl,
          objectId,
          permLevel.id,
          currentLevelPermissions[0].identityProvider,
          currentLevelPermissions,
          msalAuthToken,
        );
      }
    }
  }
};
