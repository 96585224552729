import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { useMemo, forwardRef } from 'react';
import { Link } from 'react-router-dom';

export default function ListItemLink(props: any) {
  const { primary, to } = props;

  const renderLink = useMemo(
    () =>
      forwardRef((itemProps: any, ref: any) => (
        <Link to={to?.toLowerCase()} ref={ref} {...itemProps} />
      )),
    [to],
  );

  return (
    <ListItem button component={renderLink}>
      <ListItemText primary={primary} />
    </ListItem>
  );
}
