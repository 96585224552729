import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import { useContext } from 'react';
import { GlobalSettingsContext } from 'vcl-common';

function stringToColor(string: any) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name: string, photo: string, width: any, height: any) {
  const sx: any = {
    bgcolor: stringToColor(name ? name : '  '),
  };
  if (width) sx.width = width;
  if (height) sx.height = height;
  return {
    sx: sx,
    src: photo,
    children: name ? `${name.split(' ')[0][0]}${name.split(' ')[1][0]}` : '',
  };
}

export default function UserAvatar(props: any) {
  const globalSettingsContext = useContext(GlobalSettingsContext);
  const width = props.width;
  const height = props.height;
  const profile = {
    name: globalSettingsContext?.profile?.user.displayName as string,
    mail: globalSettingsContext?.profile?.user.mail as string,
    photo: globalSettingsContext?.profile?.profilePic as string,
  };

  return (
    <Tooltip title={profile.name}>
      <Avatar {...stringAvatar(profile.name, profile.photo, width, height)} />
    </Tooltip>
  );
}
