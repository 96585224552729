import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function ConfirmDeleteDialog(props: any) {
  const { show, title, onConfirm, onClose, customDeleteMsg } = props;
  const displayTitle = title ? <strong>{title}</strong> : 'this item';

  return (
    <Dialog
      open={show}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Confirm delete</DialogTitle>
      <DialogContent>
        {customDeleteMsg && (
          <DialogContentText paragraph id="alert-dialog-description">
            {customDeleteMsg}
          </DialogContentText>
        )}
        <DialogContentText paragraph id="alert-dialog-description">
          Are you sure you want to delete {displayTitle}? This cannot be undone.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onConfirm}>Yes, delete</Button>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}
