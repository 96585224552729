import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Button } from '@mui/material';

export default function LookupLink(props: any) {
  const { link, displayText, openInNewTab } = props;

  return (
    <div>
      <Button
        onClick={(event) => event.stopPropagation()}
        className="btnText"
        size="small"
        color="secondary"
        endIcon={<KeyboardArrowRightIcon />}
        href={link}
        target={openInNewTab ? '_blank' : '_self'}
      >
        {displayText}
      </Button>
    </div>
  );
}
