import TextField from '@mui/material/TextField';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import TabbedEditor from '../sharedComponents/tabbedEditor';
import TabPanel from '../sharedComponents/tabPanel';
import { Constants } from 'vcl-common';
import { validateMetadata, validateRequired } from '../utils/validators';
import { EntityProp, initEntityMetadata } from '../utils/entityMetadata';

const initMetadata = (current?: any) =>
  initEntityMetadata(
    [
      new EntityProp('id', 0),
      new EntityProp('key', '', [validateRequired]),
      new EntityProp('value', '', [validateRequired]),
    ],
    current,
  );

export default function GlobalConfigValue() {
  const { itemId } = useParams() as any;

  const [activeTab, setActiveTab] = useState(0);
  const [metadata, setMetadata] = useState(initMetadata());
  const [canEdit, setCanEdit] = useState(false);

  const tabs = [
    {
      label: 'General',
      components: (
        <TabPanel value={activeTab} key="panel_0" index={0} mode="tabs">
          <TextField
            fullWidth
            required
            id="key"
            name="key"
            label="Key"
            value={metadata.key.value}
            error={!metadata.key.isValid}
            onChange={(event) => setMetadata(validateMetadata(metadata, event))}
            disabled={!canEdit}
          />
          <TextField
            fullWidth
            required
            id="value"
            name="value"
            label="Value"
            value={metadata.value.value}
            error={!metadata.value.isValid}
            onChange={(event) => setMetadata(validateMetadata(metadata, event))}
            disabled={!canEdit}
          />
        </TabPanel>
      ),
    },
  ];

  return (
    <TabbedEditor
      entityName="Global Configuration Value"
      apiUrl={Constants.routes.api.globalConfig}
      entityRoute={Constants.routes.adminCenter.globalConfig}
      tabs={tabs}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      metadata={metadata}
      setMetadata={setMetadata}
      canEdit={canEdit}
      canDelete={canEdit}
      setCanEdit={setCanEdit}
      usePermissions={false}
      initMetadataFn={initMetadata}
      entityId={itemId}
    />
  );
}
