import { useContext } from 'react';
import VCLDataGrid from '../sharedComponents/vclDataGrid';
import { Constants, GlobalSettingsContext, IGlobalSettings } from 'vcl-common';

export default function Users() {
  const globalSettings = useContext<IGlobalSettings>(GlobalSettingsContext);

  const columns = [
    { field: 'userName', headerName: 'Full Name', width: 300, sortable: false },
    { field: 'fullName', headerName: 'User Name', width: 300, sortable: false },
    {
      field: 'identityProviderString',
      headerName: 'Identity Provider',
      width: 300,
      sortable: false,
    },
  ];

  return (
    <VCLDataGrid
      title="Users"
      apiUrl={Constants.routes.api.users}
      entityRoute={Constants.routes.adminCenter.users}
      getPersonalizedData={false}
      gridColumns={columns}
      canUserAdd={
        globalSettings.userInfo ? globalSettings.userInfo.isGlobalAdmin : false
      }
      canUserViewGrid={globalSettings.userInfo?.isGlobalAdmin ?? false}
    />
  );
}
