import { parseISO } from 'date-fns';

export const getLocalDateFromString = (dateStr: string | string[]) => {
  if (!dateStr || dateStr === '') return null;
  else if (typeof dateStr === 'object') return dateStr;
  else if (typeof dateStr === 'string') {
    const ds = dateStr.indexOf('Z') < 0 ? `${dateStr}Z` : dateStr;
    return parseISO(ds);
  }
};
