import { Paper, Tab, Tabs } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import 'date-fns';
import ProgressSpinner from './progressSpinner';

const useStyles = makeStyles()((theme) => ({
  stepRoot: {
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2),
      paddingTop: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },
  stepContentRoot: {
    [theme.breakpoints.down('lg')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  tabsContainer: {
    maxWidth: '1200',
    '& #saveButton': {
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(4),
    },
    '& #cancelButton': {
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(4),
    },
    '& #deleteButton': {
      marginRight: theme.spacing(4),
      marginBottom: theme.spacing(4),
    },
  },
  tabs: {
    backgroundColor: '#E1DFDD',
  },
  tab: {
    [theme.breakpoints.up('md')]: {
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
    },
  },
  button: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  fixedFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    position: 'sticky',
    maxWidth: '100%',
    bottom: '0px',
    height: theme.spacing(8),
    paddingTop: theme.spacing(4),
    borderTop: '1px solid #ccc',
  },
}));

export default function TabbedContent(props: any) {
  const {
    children,
    sections,
    mode,
    activeTab,
    onTabChange,
    onFinish,
    loading,
    ...other
  } = props;
  const { classes } = useStyles();

  return (
    <Paper className={classes.tabsContainer}>
      {loading && <ProgressSpinner />}
      {!loading && (
        <>
          <Tabs
            indicatorColor="secondary"
            textColor="secondary"
            variant="scrollable"
            scrollButtons="auto"
            className={classes.tabs}
            value={activeTab}
            onChange={onTabChange}
            {...other}
          >
            {sections.map((item: any, index: any) => (
              <Tab
                key={index}
                className={classes.tab}
                label={item.label}
                {...a11yProps(index)}
              />
            ))}
          </Tabs>
          {sections.map((item: any, index: any) => item.components)}
          {children}
        </>
      )}
    </Paper>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
