import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useEffect, useState } from 'react';

const columns = [
  { id: 'name', label: 'Name', minWidth: 170 },
  { id: 'code', label: 'ISO\u00a0Code', minWidth: 100 },
  {
    id: 'population',
    label: 'Population',
    minWidth: 170,
    align: 'right',
    format: (value: any) => value.toLocaleString('en-US'),
  },
  {
    id: 'size',
    label: 'Size\u00a0(km\u00b2)',
    minWidth: 170,
    align: 'right',
    format: (value: any) => value.toLocaleString('en-US'),
  },
];

function createData(
  name: string,
  code: string,
  population: number,
  size: number,
) {
  const density = population / size;
  return { name, code, population, size, density };
}

export default function AuditLogListing(props: any) {
  const { id } = props;

  const [page, setPage] = useState(0);
  const [entries, setEntries] = useState<any[]>([]);

  useEffect(() => {
    console.info('auditLogListing | useEffect');
    if (id) {
      setEntries(
        entries.concat([
          createData(`${id} - India`, 'IN', 1324171354, 3287263),
          createData(`${id} - China`, 'CN', 1403500365, 9596961),
          createData(`${id} - Italy`, 'IT', 60483973, 301340),
          createData(`${id} - United States`, 'US', 327167434, 9833520),
          createData(`${id} - Canada`, 'CA', 37602103, 9984670),
          createData(`${id} - Australia`, 'AU', 25475400, 7692024),
          createData(`${id} - Germany`, 'DE', 83019200, 357578),
          createData(`${id} - Ireland`, 'IE', 4857000, 70273),
          createData(`${id} - Mexico`, 'MX', 126577691, 1972550),
          createData(`${id} - Japan`, 'JP', 126317000, 377973),
        ]),
      );
    }
  }, [page, entries, id]);

  return (
    <>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align as any}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {entries.map((entry) => {
              return (
                <TableRow hover tabIndex={-1} key={entry.code}>
                  {columns.map((column) => {
                    const value = entry[column.id];
                    return (
                      <TableCell key={column.id} align={column.align as any}>
                        {column.format && typeof value === 'number'
                          ? column.format(value)
                          : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
          <TableFooter>
            <Button onClick={() => setPage(page + 1)}>More</Button>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
}
