import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider,
} from '@mui/material/styles';
import { useState, useEffect, useContext } from 'react';
import {
  VclApi,
  Constants,
  GlobalSettingsContext,
  formatAxiosError,
  MSALAuthService,
  AuthContext,
} from 'vcl-common';
import AdminCenter from './AdminCenter';
import { MsalProvider } from '@azure/msal-react';
import { AxiosError } from 'axios';

declare module '@mui/material/styles' {
  interface PaletteOptions {
    chipGrey?: {
      main: string;
      light: string;
      dark?: string;
      contrastText?: string;
    };
  }
}

const theme: any = createTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#3D5881',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: '#68875C',
    },
    // error: {
    //   main: '#A13432',
    // },
    warning: {
      main: '#BF834B',
    },
    info: {
      main: '#878685',
    },
    success: {
      main: '#68875C',
    },
    chipGrey: {
      main: '#222222',
      light: '#888888',
      dark: '#090909',
      contrastText: '#DDDDDD',
    },
  },
});

export default function App() {
  const authContext = useContext(AuthContext);
  const authService = authContext.authService as MSALAuthService;
  const [globalSettings, setGlobalSettings] = useState<any>(null);

  useEffect(() => {
    console.info('app | useEffect');
    const getAuthResult = async () => {
      await authService.init();

      try {
        const loggedIn = authService.isSignedIn();
        if (!loggedIn) {
          await authService.signIn();
        } else {
          const accessToken = await authService.getAccessToken();
          const api = new VclApi('', accessToken);
          const response = await api.get(
            Constants.routes.api.authOrgAdmin,
            { url: window.location.href },
            true,
            accessToken,
          );
          if (response && response.data) {
            const data = response.data as any;
            setGlobalSettings(data.globalSettings);
          }
        }
      } catch (error: unknown | AxiosError) {
        const message = formatAxiosError(error);
        setGlobalSettings({ error: true, errorMessage: message });
      }
    };

    getAuthResult();
  }, [authService]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <MsalProvider instance={authService.msalInstance}>
          {
            <GlobalSettingsContext.Provider value={globalSettings}>
              <AdminCenter />
            </GlobalSettingsContext.Provider>
          }
        </MsalProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
