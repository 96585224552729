import { ChangeEvent } from 'react';

export const validate = (value: any, validators: string | any[]) => {
  if (!validators || validators.length === 0) return true;

  let isValid = true;

  for (let i = 0; i < validators.length; i++) {
    isValid = validators[i]({ value: value });
    if (!isValid) {
      isValid = false;
      break;
    }
  }
  return isValid;
};

export const validateMetadata = (
  metadata: { [x: string]: any },
  event:
    | ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    | (Event & { target: { value: any; name: string } })
    | { target: { value: any; name: string } },
  customTargetName?: string | boolean | undefined,
  validationCondition?: boolean | undefined,
) => {
  if (validationCondition !== undefined && validationCondition === false)
    return true;

  const eventTargetName =
    !event.target || !event.target.name ? customTargetName : event.target.name;
  const entity = metadata[eventTargetName as string];

  return {
    ...metadata,
    [eventTargetName as string]: {
      value: event.target.value,
      isValid: validate(event.target.value, entity.validators),
      validators: entity.validators,
    },
  };
};

export const validateRequired = (props: {
  value: any;
  performValidationCondition: any;
}) => {
  const { value, performValidationCondition } = props;
  if (
    performValidationCondition !== undefined &&
    performValidationCondition === false
  )
    return true;
  if (value !== null && value !== undefined && value.toString().length > 0)
    return true;

  return false;
};

export const validateUrl = (props: {
  value: any;
  performValidationCondition: any;
}) => {
  const { value, performValidationCondition } = props;

  if (
    performValidationCondition !== undefined &&
    performValidationCondition === false
  )
    return true;

  const urlPattern =
    /^(http|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&:/~+#-]*[\w@?^=%&;/~+#-])?$/;
  return urlPattern.test(value);
};

export const validateEmail = (props: {
  value: any;
  performValidationCondition: any;
}) => {
  const { value, performValidationCondition } = props;

  if (
    performValidationCondition !== undefined &&
    performValidationCondition === false
  )
    return true;

  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return emailPattern.test(value);
};
