import { Button, CircularProgress, Snackbar } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import { useEffect, useRef, useState } from 'react';
export const Types = {
  delayedAction: 'DelayedAction',
  success: 'Success',
  error: 'Error',
  warning: 'Warning',
  info: 'Info',
};

const useStyles = makeStyles()({
  bar: {
    '& .MuiSnackbarContent-root': {
      color: 'inherit',
      backgroundColor: 'inherit',
    },
  },
});

export default function MessageBar(props: any) {
  const theme = useTheme();
  const { classes } = useStyles();
  const {
    confirmationType,
    buttonText,
    onButtonClick,
    message,
    performAction,
    delayTimeMS,
    reset,
    ...other
  } = props;

  const panicButtonTimer = useRef<any>(null);

  const [delayActive, setDelayActive] = useState(true);
  useEffect(() => {
    console.info('messageBar | useEffect');
    if (panicButtonTimer.current) {
      clearTimeout(panicButtonTimer.current);
      panicButtonTimer.current = null;
    }
    if (
      confirmationType === Types.delayedAction ||
      confirmationType === Types.success
    ) {
      if (delayActive) {
        panicButtonTimer.current = setTimeout(() => {
          setDelayActive(false);
          if (!reset && performAction && typeof performAction === 'function')
            performAction();
        }, delayTimeMS || 3000);
      }
    }
    return () => clearTimeout(panicButtonTimer.current);
  }, [delayActive, confirmationType, performAction, reset, delayTimeMS]);

  let colorTheme = theme.palette.info;

  switch (confirmationType) {
    case Types.delayedAction:
      colorTheme = theme.palette.info;
      break;
    case Types.success:
      colorTheme = theme.palette.success;
      break;
    case Types.error:
      colorTheme = theme.palette.error;
      break;
    case Types.warning:
      colorTheme = theme.palette.warning;
      break;
    default:
      colorTheme = theme.palette.info;
  }

  const buttonAction = () => {
    if (panicButtonTimer.current && delayActive) {
      clearTimeout(panicButtonTimer.current);
      panicButtonTimer.current = null;
    }
    onButtonClick();
  };

  const actionAreaContent = delayActive ? (
    <Button onClick={buttonAction} style={{ color: colorTheme.light }}>
      {buttonText}
    </Button>
  ) : (
    <CircularProgress
      style={{
        color: colorTheme.light,
        width: 20,
        height: 20,
        marginRight: theme.spacing(1),
      }}
    />
  );

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      style={{
        color: colorTheme.contrastText,
        backgroundColor: colorTheme.dark,
      }}
      className={classes.bar}
      message={message}
      {...other}
      action={actionAreaContent}
    />
  );
}
