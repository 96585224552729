import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { imageQueries } from 'vcl-common';

export default function ImageViewDialog(props: any) {
  const imquery =
    props.format === 'hero'
      ? imageQueries.heroMaxSize
      : imageQueries.videoPoster;

  return (
    <Dialog
      fullWidth={true}
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="image-view-dialog-title"
      maxWidth="xl"
    >
      <DialogTitle id="image-view-dialog-title">
        {props.imageCaption}
      </DialogTitle>
      <DialogContent>
        <img
          src={`${props.image}?${imquery}`}
          alt={props.imageCaption}
          className="imageViewWidth"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
