import { IconButton, Paper, Typography, Stack } from '@mui/material';
import UserAvatar from './userAvatar';
import EmailIcon from '@mui/icons-material/Email';
import SendIcon from '@mui/icons-material/Send';

export default function ContactCard(props: any) {
  const { name, upn, email } = props;

  return (
    <Paper>
      <UserAvatar name={name} upn={upn} width={100} height={100} />
      <Typography>{name}</Typography>
      <Stack direction="row">
        <IconButton href={'mailto:' + email}>
          <EmailIcon />
        </IconButton>
        <IconButton
          href={'https://teams.microsoft.com/l/chat/0/0?users=' + email}
          target="_blank"
        >
          <SendIcon />
        </IconButton>
      </Stack>
    </Paper>
  );
}
