import { useContext } from 'react';
import VCLDataGrid from '../sharedComponents/vclDataGrid';
import { Constants, GlobalSettingsContext } from 'vcl-common';

export default function Configuration() {
  const globalSettings: any = useContext(GlobalSettingsContext);

  const columns = [
    {
      field: 'key',
      headerName: 'Key',
      width: 300,
      sortable: false,
    },
    {
      field: 'value',
      headerName: 'Value',
      width: 300,
      sortable: false,
    },
  ];

  return (
    <VCLDataGrid
      title="Global Configuration"
      entityRoute={Constants.routes.adminCenter.globalConfig}
      apiUrl={Constants.routes.api.globalConfig}
      getPersonalizedData={false}
      gridColumns={columns}
      canUserAdd={globalSettings.userInfo.isGlobalAdmin}
      canUserViewGrid={globalSettings.userInfo.isGlobalAdmin}
    />
  );
}
