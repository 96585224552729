import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import ImageViewCard from '../sharedComponents/imageViewCard';
import LazyAutocomplete from '../sharedComponents/lazyAutocomplete';
import TabbedEditor from '../sharedComponents/tabbedEditor';
import TabPanel from '../sharedComponents/tabPanel';
import { validateRequired, validateMetadata } from '../utils/validators';
import {
  Constants,
  GlobalSettingsContext,
  formatImageFilename,
  getDefaultHeroFromParent,
  getDefaultThumbnailFromParent,
  getHostDependentImageUrl,
} from 'vcl-common';
import { channelAvailablePermissions } from '../utils/permissions';
import { EntityProp, initEntityMetadata } from '../utils/entityMetadata';
import { FormControlLabel, Switch } from '@mui/material';

const initMetadata = (current?: { [x: string]: any }) =>
  initEntityMetadata(
    [
      new EntityProp('id', 0),
      new EntityProp('title', '', [validateRequired]),
      new EntityProp('description', ''),
      new EntityProp('descriptionFieldContainsHTML', false),
      new EntityProp('pathName', '', [validateRequired]),
      new EntityProp('customHeroUrl', null),
      new EntityProp('customThumbnailUrl', null),
      new EntityProp('defaultHeroUrl', null),
      new EntityProp('defaultThumbnailUrl', null),
      new EntityProp('region', null),
      new EntityProp('regionId', null, [validateRequired]),
      new EntityProp('permissions', []),
      new EntityProp('availablePermissionLevels', channelAvailablePermissions),
      new EntityProp('isOpen', false),
    ],
    current,
  );

export default function Channel() {
  const { itemId } = useParams() as any;

  const [activeTab, setActiveTab] = useState(0);
  const [metadata, setMetadata] = useState(initMetadata());
  const [canEdit, setCanEdit] = useState(false);

  const globalSettings: any = useContext(GlobalSettingsContext);

  const isNewRequest = itemId === 'new';
  const handleCheckedChange = (
    event: any,
    value: any,
    performDependencyValidation?: any,
    ...dependentControlNames: any[]
  ) => {
    const updateColl = {
      [event.target.name]: {
        value: value,
        isValid: true,
      },
    };
    dependentControlNames.forEach((name: any, index) => {
      const val = metadata[name].value;

      updateColl[name] = {
        value: val,
        isValid:
          performDependencyValidation === false ||
          isNewRequest ||
          (val && val.length > 0)
            ? true
            : false,
      };
    });

    setMetadata({ ...metadata, ...updateColl });
  };
  const tabs = [
    {
      label: 'General',
      components: (
        <TabPanel value={activeTab} key="panel_0" index={0} mode="tabs">
          <TextField
            fullWidth
            required
            id="title"
            name="title"
            label="Title"
            value={metadata.title.value}
            error={!metadata.title.isValid}
            onChange={(event) => setMetadata(validateMetadata(metadata, event))}
            disabled={!canEdit}
          />
          <TextField
            fullWidth
            multiline
            id="description"
            name="description"
            label="Description"
            value={metadata.description.value}
            onChange={(event) => setMetadata(validateMetadata(metadata, event))}
            disabled={!canEdit}
          />
          <FormControlLabel
            control={
              <Switch
                checked={metadata.descriptionFieldContainsHTML.value}
                onChange={(event, value) => handleCheckedChange(event, value)}
                name="descriptionFieldContainsHTML"
                color="primary"
                disabled={!canEdit}
              />
            }
            label="Allow HTML tags in channel description"
          />
          <TextField
            fullWidth
            required
            id="pathName"
            name="pathName"
            label="Unique link"
            value={metadata.pathName.value}
            error={!metadata.pathName.isValid}
            onChange={(event) => setMetadata(validateMetadata(metadata, event))}
            disabled={!canEdit}
            helperText={`This is the last part of the link to the channel. For example ${process.env['REACT_APP_PUBLICWEB_URL']}/mychannel`}
          />
          <LazyAutocomplete
            initialValue={metadata.region?.value}
            label="Region"
            error={metadata.regionId.isValid === false}
            onNavigate={(item: any) =>
              window.open(`${Constants.routes.adminCenter.regions}/${item.id}`)
            }
            disabled={itemId !== 'new' || !canEdit}
            apiUrl={Constants.routes.api.regions}
            metadata={metadata}
            setMetadata={setMetadata}
            idColumnName="regionId"
            valColumnName="region"
            required
          />
          <Grid>
            <FormControlLabel
              control={
                <Switch
                  checked={metadata.isOpen != null && metadata.isOpen.value}
                  onChange={(event, value) => {
                    setMetadata({
                      ...metadata,
                      [event.target.name]: {
                        value: value,
                        isValid: true,
                      },
                    });
                  }}
                  name="isOpen"
                  color="primary"
                  disabled={!canEdit}
                />
              }
              label="Available to external users"
            />
          </Grid>
          <Grid container spacing={2} alignItems="top">
            <Grid item>
              <ImageViewCard
                newImageFileName={formatImageFilename(
                  'channel-hero',
                  metadata.id.value,
                  metadata.title.value,
                )}
                customImageUrl={getHostDependentImageUrl(
                  metadata.customHeroUrl.value,
                )}
                fnUpdateImage={(newUrl: any) =>
                  setMetadata(
                    validateMetadata(metadata, {
                      target: {
                        name: 'customHeroUrl',
                        value: newUrl,
                      },
                    }),
                  )
                }
                imageTitle="Hero image"
                defaultImageUrl={getHostDependentImageUrl(
                  getDefaultHeroFromParent(metadata.region?.value),
                )}
                descriptiveText="This image is used as hero image (top image) on the channel page and during countdown for webcasts in this channel."
                disabled={!canEdit}
                parentType="region"
                parentName={metadata.region?.value?.title}
              />
            </Grid>
            <Grid item>
              <ImageViewCard
                newImageFileName={formatImageFilename(
                  'channel-thumb',
                  metadata.id.value,
                  metadata.title.value,
                )}
                customImageUrl={getHostDependentImageUrl(
                  metadata.customThumbnailUrl.value,
                )}
                fnUpdateImage={(newUrl: any) =>
                  setMetadata(
                    validateMetadata(metadata, {
                      target: {
                        name: 'customThumbnailUrl',
                        value: newUrl,
                      },
                    }),
                  )
                }
                imageTitle="Thumbnail image"
                defaultImageUrl={getHostDependentImageUrl(
                  getDefaultThumbnailFromParent(metadata.region?.value),
                )}
                descriptiveText="This image is used as thumbnail for webcasts in this channel."
                disabled={!canEdit}
                parentType="region"
                parentName={metadata.region?.value?.title}
              />
            </Grid>
          </Grid>
        </TabPanel>
      ),
    },
  ];

  return (
    <TabbedEditor
      entityName="Channel"
      apiUrl={Constants.routes.api.channels}
      entityRoute={Constants.routes.adminCenter.channels}
      tabs={tabs}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      metadata={metadata}
      setMetadata={setMetadata}
      canEdit={canEdit}
      canDelete={globalSettings.userInfo.isRegionAdminOfAny}
      canEditPermissions={globalSettings.userInfo.isRegionAdminOfAny}
      setCanEdit={setCanEdit}
      usePermissions
      initMetadataFn={initMetadata}
      entityId={itemId}
      customDeleteMsg="If you proceed, all connected webcasts will be disconnected from this channel."
    />
  );
}
