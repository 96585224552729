import Box from '@mui/material/Box';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  inputs: {
    '& .MuiTextField-root, & .MuiFormControl-root, & .MuiFormControlLabel-root, & .MuiCard-root, && #saveButton':
      {
        margin: theme.spacing(1),
      },
    '& .MuiInputBase-fullWidth': {
      width: `calc(100% - ${theme.spacing(2)})`,
    },
  },
  inputsStepper: {
    '& .MuiTextField-root, & .MuiFormControl-root, & .MuiFormControlLabel-root, & .MuiCard-root, && #saveButton':
      {
        marginBottom: theme.spacing(1),
      },
  },
}));

export default function TabPanel(props: any) {
  const { children, value, index, mode, ...other } = props;

  const { classes } = useStyles();

  return (
    <div
      className={classes.inputs}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={mode === 'stepper' ? 1 : 3}>{children}</Box>}
    </div>
  );
}
