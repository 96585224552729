import { Grid, CircularProgress } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  progressContainer: {
    minHeight: '100vh',
  },
}));

export default function ProgressSpinner() {
  const { classes } = useStyles();

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      justifyContent="center"
      alignItems="center"
      className={classes.progressContainer}
    >
      <Grid item>
        <CircularProgress color="inherit" />
      </Grid>
    </Grid>
  );
}
