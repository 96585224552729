import MessageBar from './messageBar';
import { PageState } from '../utils/saveProcess';

export default function SaveStateMessages(props: any) {
  const { updateProgess, saveState, saveAction, entityTypeName, errorMessage } =
    props;

  return (
    <>
      {saveState === PageState.SAVING && (
        <MessageBar
          open={true}
          message={`Saving ${entityTypeName.toLowerCase()}...`}
          key="snack-delayed"
          confirmationType="DelayedAction"
          buttonText="Cancel"
          onButtonClick={() => updateProgess(PageState.DIRTY)}
          performAction={saveAction}
          delayTimeMS={1000}
        ></MessageBar>
      )}
      <MessageBar
        open={saveState === PageState.SAVE_SUCCESS}
        message={`${entityTypeName} saved`}
        key="snack-success"
        confirmationType="Success"
        buttonText="OK"
        onButtonClick={() => updateProgess(PageState.UNTOUCHED)}
      ></MessageBar>
      <MessageBar
        open={saveState === PageState.SAVE_ERROR}
        message={'Error' + (errorMessage ? `: ${errorMessage}` : '')}
        key="snack-error"
        confirmationType="Error"
        buttonText="OK"
        onButtonClick={() => updateProgess(PageState.DIRTY)}
      ></MessageBar>
    </>
  );
}
