import React from 'react';
import { createRoot } from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import App from './App';
import './master.css';
import { msalConfig, loginRequest } from './utils/msalConfig';
import { AuthContext, MSALAuthService } from 'vcl-common';

const container = document.getElementById('root');
if (container) {
  const root = createRoot(container);
  root.render(
    <AuthContext.Provider
      value={{
        authService: new MSALAuthService(msalConfig, loginRequest),
      }}
    >
      <App />
    </AuthContext.Provider>,
  );
}

reportWebVitals(null);
//need to handle the web vitals
