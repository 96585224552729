import AddIcon from '@mui/icons-material/Add';
import Fab from '@mui/material/Fab';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  fab: {
    bottom: theme.spacing(14),
    position: 'absolute',
    [theme.breakpoints.up('md')]: {
      right: theme.spacing(7),
    },
    [theme.breakpoints.down('md')]: {
      right: theme.spacing(1),
    },
  },
}));

export default function AddButton(props: any) {
  const { classes } = useStyles();
  return (
    <Fab
      color="secondary"
      className={classes.fab}
      aria-label="add"
      onClick={props.handleClick}
      disabled={props.disable}
    >
      <AddIcon />
    </Fab>
  );
}
