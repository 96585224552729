export class EntityProp {
  name: string;
  defaultValue:
    | string
    | number
    | boolean
    | { id: number; title: string }[]
    | null
    | undefined;
  validators:
    | ((props: { value: any; performValidationCondition: any }) => boolean)[]
    | null
    | undefined;
  modifiers:
    | ((dateStr: string | string[]) => string[] | Date | null | undefined)[]
    | null
    | undefined;
  dontValidateOnInitialLoad: boolean | undefined;

  constructor(
    name: string,
    defaultValue:
      | string
      | number
      | boolean
      | { id: number; title: string }[]
      | null
      | undefined,
    validators?:
      | ((props: { value: any; performValidationCondition: any }) => boolean)[]
      | null
      | undefined,
    modifiers?:
      | ((dateStr: string | string[]) => string[] | Date | null | undefined)[]
      | null
      | undefined,
    dontValidateOnInitialLoad?: boolean | undefined,
  ) {
    this.name = name;
    this.defaultValue = defaultValue;
    this.validators = validators;
    this.modifiers = modifiers;
    this.dontValidateOnInitialLoad = dontValidateOnInitialLoad;
  }
}

// Create an array with validated metadata from the entity if it exists,
// otherwise create metadata based on the default values of the props
export const initEntityMetadata = (
  props: any[],
  entity?: { [x: string]: any },
) => {
  const metadata: any = {};

  props.forEach((prop, index) => {
    metadata[prop.name] = { value: null, isValid: true, validators: null };

    if (entity) {
      let val = entity[prop.name];
      prop.modifiers?.forEach((modifier: any) => (val = modifier(val)));
      metadata[prop.name].value = val;
    } else {
      metadata[prop.name].value = prop.defaultValue;
    }
    metadata[prop.name].validators = prop.validators;

    // Invalidate the metadata if one of the validators return false
    if (!prop.dontValidateOnInitialLoad && prop.validators) {
      for (let i = 0; i < prop.validators.length; i++) {
        const isValid = entity
          ? prop.validators[i]({ value: entity[prop.name] })
          : prop.validators[i]({ value: prop.defaultValue });
        if (!isValid) {
          metadata[prop.name].isValid = false;
          break;
        }
      }
    }
  });

  return metadata;
};
