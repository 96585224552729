import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useContext } from 'react';
import LookupLink from '../sharedComponents/lookupLink';
import VCLDataGrid from '../sharedComponents/vclDataGrid';
import {
  Constants,
  GlobalSettingsContext,
  getHostDependentImageUrl,
} from 'vcl-common';
import { Avatar } from '@mui/material';

export default function Regions() {
  const globalSettings: any = useContext(GlobalSettingsContext);

  const theme = useTheme();
  const isXSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const columns = [
    {
      field: 'thumbnailUrl',
      headerName: '',
      width: 50,
      sortable: false,
      renderCell: (params: { value: string }) => {
        return <Avatar src={getHostDependentImageUrl(params.value)} alt="" />;
      },
    },
    { field: 'title', headerName: 'Title', width: 300, sortable: false },
    {
      field: 'organization',
      headerName: 'Organization',
      width: 300,
      sortable: false,
      valueFormatter: (params: { value: { title: any } | null }) => {
        return params.value !== null ? params.value.title : '';
      },
      renderCell: (params: { value: { id: any; title: any } | null }) => {
        if (params.value !== null) {
          return (
            <LookupLink
              link={`${Constants.routes.adminCenter.organizations}/${params.value.id}`}
              displayText={params.value.title}
            />
          );
        } else return null;
      },
      disableClickEventBubbling: true,
      valueParser: (value: any) => value,
      hide: isXSmallScreen,
    },
  ];

  return (
    <VCLDataGrid
      title="Regions"
      entityRoute={Constants.routes.adminCenter.regions}
      apiUrl={Constants.routes.api.regions}
      getPersonalizedData={true}
      gridColumns={columns}
      canUserAdd={globalSettings.userInfo.isOrgAdminOfAny}
      canUserViewGrid={globalSettings.userInfo.isRegionAdminOfAny}
    />
  );
}
