import Chip from '@mui/material/Chip';
import { useTheme } from '@mui/material/styles';

export default function StatusChip(props: any) {
  const theme = useTheme();

  let chipPalette = theme.palette.info;

  switch (props.statusText) {
    case 'Live':
      chipPalette = theme.palette.success;
      break;
    case 'Published':
      chipPalette = theme.palette.secondary;
      break;
    case 'Ended':
      chipPalette = theme.palette.primary;
      break;
    case 'Unpublished':
      chipPalette = theme.palette.warning;
      break;
    case 'Draft':
      chipPalette = theme.palette.warning;
      break;
    default:
      chipPalette = theme.palette.info;
  }

  return (
    <Chip
      size="small"
      label={props.statusText}
      style={{
        backgroundColor: chipPalette.main,
        color: chipPalette.contrastText,
      }}
    />
  );
}
